import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./swipers.scss";
import { useState } from "react";


interface CirclesProps {
    images: string[];
    
}


const CirclesSwiper:React.FC<CirclesProps> = ({images}) => {

    return (
        <div className="gallery-wrapper">
            <SwiperComponent
                id="circles"                
                modules={[Autoplay]}            
                slidesPerView={4}
                initialSlide={3}
                spaceBetween={10}
                centeredSlides={true}                
                loop={true}
                autoplay={{ delay: 2000 }}                
            >
                {images.map((image: string, index: number) => {
                    return (
                        <SwiperSlide key={index}>
                            <div
                                className="swiper-image"
                                style={{ backgroundImage: `URL(${image})` }}
                            />
                        </SwiperSlide>
                    );
                })}
            </SwiperComponent>               
        </div>
    )

}

export default CirclesSwiper;