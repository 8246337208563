import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import "./scss/global.scss";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Bar from "./components/bar/bar";
import Design from "./pages/design/design";
import Icon from "./pages/icon/icon";
import CookieBanner from "./components/cookies/cookie-banner";

const App: React.FC = () => {
    const routes = [
        { path: "/", component: Home },
        { path: "/design", component: Design },
        { path: "/icon", component: Icon },
    ];

    return (
        <>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.component />}
                            />
                        );
                    })}
                </Routes>
                <Footer />
                <CookieBanner />
                <Bar />
            </BrowserRouter>
        </>
    );
};

export default App;
