const Collab = () => {

    return(
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <defs>
            <style>
                {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
            </style>
        </defs>
        <line className="cls-2" x1="35.72" y1="143.06" x2="35.72" y2="158.36" />
        <line className="cls-2" x1="93.48" y1="158.36" x2="93.48" y2="143.06" />
        <g>
            <line
                className="cls-2"
                x1="139.58"
                y1="94.04"
                x2="194.89"
                y2="94.04"
            />
            <line
                className="cls-2"
                x1="167.24"
                y1="94.04"
                x2="167.24"
                y2="156.28"
            />
            <line
                className="cls-2"
                x1="154.07"
                y1="156.26"
                x2="180.4"
                y2="156.26"
            />
        </g>
        <path
            className="cls-2"
            d="M108.78,127.31V58.12c0-9.1-7.38-16.48-16.48-16.48h-55.39c-9.1,0-16.48,7.38-16.48,16.48v69.19s88.35,0,88.35,0Z"
        />
        <path
            className="cls-1"
            d="M93.48,143.06h30.62v-45.95c0-8.46-6.85-15.31-15.31-15.31h0c-8.46,0-15.31,6.85-15.31,15.31v15.33h-57.76v-15.33c0-8.46-6.85-15.31-15.31-15.31h0c-8.46,0-15.31,6.85-15.31,15.31v45.95h88.38Z"
        />
        <line className="cls-2" x1="147" y1="72.78" x2="139.58" y2="72.78" />
        <path
            className="cls-1"
            d="M147,65.77h14.01v15.04c0,3.02-2.45,5.47-5.47,5.47h-3.07c-3.02,0-5.47-2.45-5.47-5.47v-15.04h0Z"
        />
    </svg>
    )
};

export default Collab;
