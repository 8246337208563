const Terrace = () => {

    return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <defs>
            <style>
                {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
            </style>
        </defs>
        <path
            className="cls-1"
            d="M121.75,159.11c0-19.4,15.93-35.12,35.57-35.12s35.57,15.72,35.57,35.12"
        />
        <path
            className="cls-1"
            d="M75.95,159.11c0-19.4,15.93-35.12,35.57-35.12s35.57,15.72,35.57,35.12"
        />
        <path
            className="cls-1"
            d="M7.11,159.11c0-27.8,22.82-50.33,50.97-50.33s50.97,22.53,50.97,50.33"
        />
        <line className="cls-2" x1="100" y1="10.43" x2="100" y2="118" />
        <line className="cls-2" x1="67.31" y1="118" x2="132.69" y2="118" />
        <line className="cls-2" x1="100" y1="118" x2="100" y2="157.89" />
        <line
            className="cls-2"
            x1="90.94"
            y1="157.87"
            x2="109.06"
            y2="157.87"
        />
        <path
            className="cls-2"
            d="M140.52,134.61h22.87c8.21,0,14.87-6.66,14.87-14.87v-21.67"
        />
        <line
            className="cls-2"
            x1="149.81"
            y1="134.61"
            x2="141.32"
            y2="157.89"
        />
        <line
            className="cls-2"
            x1="169.76"
            y1="134.61"
            x2="178.26"
            y2="157.89"
        />
        <path
            className="cls-2"
            d="M59.48,134.61h-22.87c-8.21,0-14.87-6.66-14.87-14.87v-21.67"
        />
        <line className="cls-2" x1="50.19" y1="134.61" x2="58.68" y2="157.89" />
        <line className="cls-2" x1="30.24" y1="134.61" x2="21.74" y2="157.89" />
        <path
            className="cls-2"
            d="M180.35,57.46c-15.79-28.07-45.85-47.03-80.35-47.03S35.44,29.39,19.65,57.46h160.7Z"
        />
        <path
            className="cls-2"
            d="M139.45,57.46c-7.75-28.07-22.51-47.03-39.45-47.03s-31.7,18.96-39.45,47.03h78.9Z"
        />
    </svg>)
};

export default Terrace;
