import { useLayoutEffect } from "react";
import "../../scss/global.scss";
import "./home.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import IntroAnimation from "../../animations/intro/intro-anim";

const Home: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
        <IntroAnimation />
            <div className="contain-wrapper">
                <div className="home-one contain">
                    <img 
                        src="../images/ui/play.svg" 
                        className="play-btn"  
                        alt="playbutton" 
                        id="play-button"
                        onClick={() => {
                            const video = document.getElementById("home-video") as HTMLVideoElement;
                            const playButton = document.getElementById("play-button") as HTMLImageElement;
                            if (video) {
                                video.play();
                                if (playButton) {
                                    playButton.style.display = "none";
                                }
                            }
                        }}
                    />
                    <video
                        id="home-video"
                        muted
                        src="https://player.vimeo.com/progressive_redirect/playback/1015196094/rendition/720p/file.mp4?loc=external&signature=de97ac857e959b0bac3f2843ce92cf2e76a9f191f7f9298de213a2369601a3c5"
                        onPause={() => {
                            const playButton = document.getElementById("play-button") as HTMLImageElement;
                            if (playButton) {
                                playButton.style.display = "block";
                            }
                        }}
                        onPlay={() => {
                            const playButton = document.getElementById("play-button") as HTMLImageElement;
                            if (playButton) {
                                playButton.style.display = "none";
                            }
                        }}
                        poster="../images/home/poster.jpg"
                        playsInline
                        controls
                    />
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="home-two contain">
                    <motion.div whileInView={{y: 0, opacity: 1}} initial={{y: 20, opacity: 0}} transition={{delay: 0.5, transition: 0.5}} className="copy-wrapper">
                        <h3>AVAILABLE NOW</h3>
                        <p>
                            Become a custodian of quality and take space in a
                            home that has made British design history.
                        </p>
                        <p>
                            15,988 sq ft of high quality, fully restored and
                            highly sustainable, Thameside office space in
                            London's newest listed building. Built by
                            Sterling-prize winner Hopkins for David Mellor CBE,
                            re-imagined by Sir Terence Conran, now updated and
                            restored to design-led, contemporary office space by
                            Squire & Partners.
                        </p>
                    </motion.div>
                    <div className="spec-wrapper">
                        <div className="borders">
                            <h4>15,988 SQ FT</h4>
                        </div>
                        <div className="borders">
                            <h4>6 FLOORS WITH THAMESIDE VIEWS</h4>
                        </div>
                        <div className="borders">
                            <h4>
                                EXCEPTIONAL VIEWS OF TOWER BRIDGE, THE SHARD AND
                                BEYOND
                            </h4>
                        </div>
                        <div className="borders">
                            <h4>HIGHLY SUSTAINABLE, DESIGN-LED OFFICE SPACE</h4>
                        </div>
                        <div className="borders">
                            <h4>FULLY FITTED FLOORS, READY TO MOVE IN</h4>
                        </div>
                        <div className="borders">
                            <h4>
                                DESIGN AND LAYOUT OPTIONS AVAILABLE FOR CAT A
                                FLOORS
                            </h4>
                        </div>
                        <div className="borders">
                            <h4>
                                MANAGED OFFICE SOLUTION OPTION PROVIDED BY <span onClick={() => window.open("https://www.kittoffices.com/en/" )}className="kitt">KITT</span>
                            </h4>
                        </div>
                        <div className="borders">
                            <a href="../dl/brochure.pdf" target="_blank">
                                <h4>DOWNLOAD BROCHURE</h4>
                                <img src="../images/ui/dot-arrow.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="home-three contain">
                    <Link className="link" to="/design">
                        <h4>DISCOVER</h4>
                        <h2>THE DESIGN</h2>
                    </Link>
                    <Link className="link" to="/icon">
                        <h4>UNCOVER</h4>
                        <h2>THE ICON</h2>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Home;
