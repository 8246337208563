import { motion } from "framer-motion";
import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <>
            <div className="contain-wrapper">
                <div className="footer-one contain">
                    <h1>BETTER</h1>
                    <h1 className="tablet-mobile">BY</h1>
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="footer-two contain">
                    <div className="green-box-wrapper">
                        <div className="green-box">
                            <h1 className="tablet-mobile">DESIGN</h1>
                            <h3>GET IN TOUCH</h3>
                            <div className="agents-wrapper">
                                <div className="agents">
                                    <img
                                        className="logo"
                                        src="../images/bray-fox-smith.svg"
                                    />
                                    <div className="agent">
                                        <p>
                                            <b>Rob Skioldebrand</b>
                                        </p>
                                        <a href="mailto:robertskioldebrand@brayfoxsmith.com">
                                            <p>
                                                robertskioldebrand@brayfoxsmith.com
                                            </p>
                                        </a>
                                        <a href="tel:+44 (0)7769 725 412">
                                            <p>+44 (0)7769 725 412</p>
                                        </a>
                                    </div>
                                    <div className="agent">
                                        <p>
                                            <b>Jake Stace</b>
                                        </p>
                                        <a href="mailto:jakestace@brayfoxsmith.com">
                                            <p>jakestace@brayfoxsmith.com</p>
                                        </a>
                                        <a href="tel:+44 (0)7597 685 889">
                                            <p>+44 (0)7597 685 889</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="agents">
                                    <img
                                        className="logo"
                                        src="../images/colliers.svg"
                                    />
                                    <div className="agent">
                                        <p>
                                            <b>Charlie Collins</b>
                                        </p>
                                        <a href="mailto:charlie.collins@colliers.com">
                                            <p>charlie.collins@colliers.com</p>
                                        </a>
                                        <a href="tel:+44 (0)7759 121 247">
                                            <p>+44 (0)7759 121 247</p>
                                        </a>
                                    </div>
                                    <div className="agent">
                                        <p>
                                            <b>Joseph Mishon</b>
                                        </p>
                                        <a href="mailto:joseph.mishon@colliers.com">
                                            <p>joseph.mishon@colliers.com</p>
                                        </a>
                                        <a href="tel:+44 (0)7876 724 488">
                                            <p>+44 (0)7876 724 488</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p>Managed office solution provided by:</p>
                            <div
                                onClick={() =>
                                    window.open(
                                        "https://www.kittoffices.com/en/"
                                    )
                                }
                                className="kitt"
                            >
                                <img
                                    className="logo"
                                    src="../images/kitt.svg"
                                    alt="kitt"
                                />
                                <div className="agent">
                                    <p>
                                        <b>Poppy Barker</b>
                                    </p>
                                    <a href="mailto:poppy.barker@kittoffices.com">
                                        <p>poppy.barker@kittoffices.com</p>
                                    </a>
                                    <a href="tel:+44 (0)7878 859 429">
                                        <p>+44 (0)7878 859 429</p>
                                    </a>
                                </div>
                                <div className="agent">
                                    <p>
                                        <b>Ben Danaher</b>
                                    </p>
                                    <a href="mailto:ben.danaher@kittoffices.com">
                                        <p>ben.danaher@kittoffices.com</p>
                                    </a>
                                    <a href="tel:+44 (0)7896 783 568">
                                        <p>+44 (0)7896 783 568</p>
                                    </a>
                                </div>
                            </div>
                            <h1 className="green-back desktop">DESIGN</h1>
                        </div>
                        <div className="green-box-two">
                            <div className="top-wrapper">
                                <h3>
                                    A DEVELOPMENT{" "}
                                    <br className="tablet-mobile" /> BY
                                </h3>
                                <div className="develop">
                                    <p>Development Manager:</p>

                                    <img
                                        src="../images/lateral.svg"
                                        alt="lateral"
                                        onClick={() =>
                                            window.open(
                                                "https://lateral.london/"
                                            )
                                        }
                                    />
                                </div>
                                <div className="develop">
                                    <p>Development/ Funding Partner:</p>
                                    <img
                                        src="../images/amazon.svg"
                                        alt="amazon"
                                        onClick={() =>
                                            window.open(
                                                "https://www.amazonproperty.com/amazon-capital/"
                                            )
                                        }
                                    />
                                </div>
                                <div className="develop">
                                    <p>Architect:</p>
                                    <img
                                        src="../images/squire.svg"
                                        alt="squire"
                                        onClick={() =>
                                            window.open(
                                                "https://squireandpartners.com/"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="misrep">
                                <p>
                                    Misrepresentation Act: The joint agents, for
                                    themselves and for the vendors or lessors of
                                    this property, whose agents they are, give
                                    notice that: These particulars are produced
                                    in good faith, but are set out as a general
                                    guide only, and do not constitute any part
                                    of a contract; No person in the employment
                                    of the joint agents has any authority to
                                    make or give any representation or warranty
                                    whatever in relation to this property;
                                    Unless otherwise stated all rents or prices
                                    quoted are exclusive of VAT which may be
                                    payable in addition. October 2024.
                                    <br />
                                    <br />
                                </p>

                                <p>
                                    siren | +44 (0)20 7478 4300 |
                                    sirendesign.co.uk
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="white-box">
                        <h1>BY</h1>
                        <div className="address">
                            <p>
                                CONRAN BUILDING
                                <br />
                                22 SHAD THAMES
                                <br />
                                SE1 2YU
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
