import { motion } from "framer-motion";
import "./bubble.scss";
import useIsMobile from "../../hooks/useIsMobile";

const BubbleAnimationTwo = () => {
    const isMobile = useIsMobile();

    return (
        <div className="bubble-hero">
            <div className="bubble-wrapper">
            {Array.from({ length: (isMobile ? 11 : 15) }).map((_, index) => (
                        <motion.div
                        initial={{ y: "-100%" }}
                        whileInView={{ y: "-50%" }}
                        transition={{ delay: 2 + Math.random() }}
                        key={index}
                        className={`bubble ${index === 7 && 'middle'}`}
                        style={{ backgroundColor: "#F3F1EC" }}
                        viewport={{ once: true }}
                    ></motion.div>
                ))}
            </div>
            <div className="bubble-wrapper">
            {Array.from({ length: (isMobile ? 11 : 15) }).map((_, index) => {
                    const colors = ["#66734B", "#F3F1EC", "transparent"];
                    let color;
                    const randomValue = Math.random();
                    if (randomValue < 0.1) {
                        color = colors[0];
                    } else if (randomValue < 0.6) {
                        color = colors[1];
                    } else {
                        color = colors[2];
                    }
                    if (index === 7) {
                        color = "transparent";
                    }

                    return (
                        <motion.div
                            initial={{ y: "-200%" }}
                            whileInView={{ y: "-50%" }}
                            transition={{ delay: 1 + Math.random() }}
                            key={index}
                            className="bubble"
                            style={{
                                backgroundColor: color,
                                position: "relative",
                            }}
                            viewport={{ once: true }}
                        ></motion.div>
                    );
                })}
            </div>
            <div className="bubble-wrapper">
            {Array.from({ length: (isMobile ? 11 : 15) }).map((_, index) => {
                    const colors = ["#66734B", "#F3F1EC", "transparent"];
                    let color;
                    const randomValue = Math.random();
                    if (randomValue < 0.1) {
                        color = colors[0];
                    } else if (randomValue < 0.6) {
                        color = colors[1];
                    } else {
                        color = colors[2];
                    }
                    if (index === 7) {
                        color = "transparent";
                    }
                    if (index === 7) {
                        color = "transparent";
                    }
                    return (
                        <motion.div
                            initial={{ y: "-300%" }}
                            whileInView={{ y: "-50%" }}
                            transition={{ delay: Math.random() }}
                            key={index}
                            className="bubble"
                            style={{ backgroundColor: color }}
                            viewport={{ once: true }}
                        ></motion.div>
                    );
                })}
            </div>
        </div>
    );
};

export default BubbleAnimationTwo;
