const Ventilation = ({ ...props }) => {
    return (
        <>
            <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
            >
                <defs>
                    <style>
                        {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
                    </style>
                </defs>
                <circle className="cls-1" cx="147.35" cy="34.98" r="8.04" />
                <circle className="cls-1" cx="47.78" cy="46.25" r="12.96" />
                <circle className="cls-1" cx="77.06" cy="144.91" r="15.62" />
                <path
                    className="cls-2"
                    d="M114.37,113.74c-1.22,3.33-1.88,6.93-1.88,10.69,0,17.19,13.94,31.13,31.13,31.13s31.13-13.94,31.13-31.13-13.94-31.13-31.13-31.13H44.06"
                />
                <path
                    className="cls-2"
                    d="M139.46,63.12c2.31-4.43,6.95-7.45,12.29-7.45,7.65,0,13.85,6.2,13.85,13.85s-6.2,13.85-13.85,13.85H58.05"
                />
                <path
                    className="cls-2"
                    d="M81.4,60.8c-.75-2.06-1.16-4.28-1.16-6.6,0-10.62,8.61-19.22,19.22-19.22s19.22,8.61,19.22,19.22-8.61,19.22-19.22,19.22H25.25"
                />
                <line
                    className="cls-2"
                    x1="100.63"
                    y1="103.27"
                    x2="26.42"
                    y2="103.27"
                />
            </svg>
        </>
    );
};

export default Ventilation;
