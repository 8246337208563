import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./swipers.scss";
import { useState } from "react";

interface GalleryProps {
    images: string[];
    captions: string[];
}

const Gallery: React.FC<GalleryProps> = ({ images, captions }) => {
    const [realIndex, setRealIndex] = useState(0);

    return (
        <div className="gallery-wrapper">
            <SwiperComponent
                id="gallery"
                modules={[Navigation]}
                spaceBetween={5}
                slidesPerView={1.25}
                centeredSlides={true}
                onSwiper={(swiper: any) => setRealIndex(swiper.realIndex)}
                onSlideChange={(swiper: any) => setRealIndex(swiper.realIndex)}
                navigation={{
                    nextEl: ".next-arrow-gallery",
                    prevEl: ".prev-arrow-gallery",
                }}
                loop={true}
                breakpoints={{
                    768: {
                        spaceBetween: 20,
                    },
                    1024: { 
                        
                        spaceBetween: 20,
                    }
                }}
            >
                {images.map((image: string, index: number) => {
                    return (
                        <SwiperSlide key={index}>
                            <div
                                className="swiper-image"
                                style={{ backgroundImage: `URL(${image})` }}
                            />
                        </SwiperSlide>
                    );
                })}
            </SwiperComponent>
                <div className="nav-bar">
                    <div className="content-wrapper">
                        <div className="prev-arrow-gallery">
                            <img src="../images/ui/green-arrow.svg" />
                        </div>
                        <h4>
                            0{realIndex + 1} / 0{images.length}
                        </h4>
                        <h4>{captions[realIndex]}</h4>
                    </div>
                    <div className="next-arrow-gallery">
                        <img src="../images/ui/green-arrow.svg" />
                    </div>
                </div>
        </div>
    );
};

export default Gallery;
