const Showers = () => {
    return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <defs>
            <style>
                {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
            </style>
        </defs>
        <g>
            <path
                className="cls-2"
                d="M89.44,48V9.05h26.28c15.68,0,28.38,12.71,28.38,28.38v134.95"
            />
            <path
                className="cls-1"
                d="M122.97,75.01c0-18.42-15.01-33.35-33.53-33.35s-33.54,14.93-33.54,33.35h67.07Z"
            />
        </g>
        <g>
            <line className="cls-2" x1="64.94" y1="81.56" x2="64.94" y2="100.31" />
            <line className="cls-2" x1="64.94" y1="107.17" x2="64.94" y2="128.12" />
            <line className="cls-2" x1="89.44" y1="117.65" x2="89.44" y2="136.95" />
            <line className="cls-2" x1="89.44" y1="81.87" x2="89.44" y2="109.83" />
            <line
                className="cls-2"
                x1="113.94"
                y1="103.13"
                x2="113.94"
                y2="128.12"
            />
            <line className="cls-2" x1="113.94" y1="81.44" x2="113.94" y2="95.13" />
        </g>
    </svg>)
};

export default Showers;
