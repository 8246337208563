import { useEffect, useState } from "react";
import "./cookie-banner.scss";
const isEssentialCookie = (cookieName: string): boolean => {
    const essentialCookies = ["sessionid", "csrftoken"];
    return essentialCookies.includes(cookieName);
};

const CookieBanner: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem("cookieConsent");
        if (!cookieConsent) {
            setIsVisible(true);
        }
    }, []);

    const handleAgree = (): void => {
        localStorage.setItem("cookieConsent", "true");
        setIsVisible(false);
    };

    const handleDisagree = (): void => {
        localStorage.setItem("cookieConsent", "false");
        setIsVisible(false);
        document.cookie.split(";").forEach((cookie) => {
            const cookieName = cookie.split("=")[0].trim();
            if (!isEssentialCookie(cookieName)) {
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        });
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-banner">
            <p>
                This website uses cookies to analyse traffic. Please accept or
                reject all non-essential cookies related to analytics.&nbsp;
                {/* <a href="/cookie-policy">Learn more</a>. */}
            </p>
            <button onClick={handleAgree}>Agree</button>
            <button onClick={handleDisagree}>Disagree</button>
        </div>
    );
};

export default CookieBanner;
