const Plants = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
        >
            <defs>
                <style>
                    {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
                </style>
            </defs>
            <g>
                <path
                    className="cls-1"
                    d="M97.74,58.84c0,1.92.13,3.81.35,5.66,1.86.23,3.75.35,5.66.35,25.76,0,46.65-20.89,46.65-46.65,0-1.92-.13-3.81-.35-5.66-1.86-.23-3.75-.35-5.66-.35-25.76,0-46.65,20.89-46.65,46.65Z"
                />
                <path
                    className="cls-1"
                    d="M55.61,12.19c-1.92,0-3.81.13-5.66.35-.23,1.86-.35,3.75-.35,5.66,0,25.76,20.89,46.65,46.65,46.65,1.92,0,3.81-.13,5.66-.35.23-1.86.35-3.75.35-5.66,0-25.76-20.89-46.65-46.65-46.65Z"
                />
            </g>
            <line className="cls-2" x1="100" y1="84.68" x2="100" y2="159.11" />
            <polyline
                className="cls-2"
                points="100 88.44 100 61.84 133.02 28.82"
            />
            <polyline
                className="cls-2"
                points="153.76 159.11 143.76 90.36 56.24 90.36 46.24 159.11"
            />
            <path
                className="cls-1"
                d="M151.42,84.68c0,28.4-23.02,51.42-51.42,51.42s-51.42-23.02-51.42-51.42h102.83Z"
            />
            <line className="cls-2" x1="100" y1="61.84" x2="81.43" y2="43.27" />
        </svg>
    );
};

export default Plants;
