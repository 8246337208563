import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";

const Nav: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [hasFinishedScrolling, setHasFinishedScrolling] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setHasScrolled(false);
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (!hasScrolled && scrollPosition > 1) {
                setHasScrolled(true);
            }
            if (hasScrolled && scrollPosition <= 1) {
                setHasScrolled(false);
                setHasFinishedScrolling(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasScrolled]);

    const variants = {
        initial: { opacity: 1, y: 0 },
        animate: {
            y: "-100%",
            opacity: 1,

            transition: { duration: 1, ease: "easeInOut" },
        },
    };

    return (
        <div
            className={`nav-hero ${
                hasScrolled && location.pathname != "/" && "active"
            }`}
        >
            <div className="placeholder"> </div>
            <div className="logo">
                <Link to={"/"}>
                    <img
                        src={`../images/logo${
                            hasScrolled && location.pathname != "/"
                                ? "-white"
                                : ""
                        }.svg`}
                        alt="logo"
                    />
                </Link>
            </div>
            <div
                className={`burger-icon ${
                    hasScrolled && location.pathname != "/" && "active"
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <motion.span
                    className={`line ${location.pathname.replace("/", "")}`}
                    initial={{ rotate: 0, y: 0 }}
                    animate={
                        isOpen
                            ? { rotate: 45, y: 11, backgroundColor: "white" }
                            : {
                                  rotate: 0,
                                  y: 0,
                                  backgroundColor: `${
                                    (hasScrolled && location.pathname != '/' ) ? "white" : "#65724A"
                                  }`,
                              }
                    }
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                />

                <motion.span
                    className={`line ${location.pathname.replace("/", "")}`}
                    initial={{ opacity: 1 }}
                    animate={isOpen ? { opacity: 0 } : { opacity: 1,backgroundColor: `${(hasScrolled && location.pathname != '/' ) ? 'white' : '#65724A'}`  }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                />
                <motion.span
                    className={`line ${location.pathname.replace("/", "")}`}
                    initial={{ rotate: 0, y: 0 }}
                    animate={
                        isOpen
                            ? { rotate: -45, y: -11, backgroundColor: "white" }
                            : { rotate: 0, y: 0, backgroundColor: `${(hasScrolled && location.pathname != '/' ) ? 'white' : '#65724A'}`  }
                    }
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                />
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="burger-menu"
                        initial={{ x: "100%" }}
                        exit={{ x: "100%" }}
                        animate={{ opacity: 1, x: "0" }}
                        transition={{ duration: 0.25 }}
                    >
                        <div className="link-wrapper">
                            <Link onClick={() => setIsOpen(false)} to="/design">
                                <h3>DESIGN</h3>
                            </Link>
                            <p className="caption">THE BUILDING & SPECIFICATION</p>
                        </div>
                        <div className="link-wrapper">
                            <Link onClick={() => setIsOpen(false)} to="/icon">
                                <h3>ICON</h3>
                            </Link>
                            <p className="caption">HERITAGE & LOCATION</p>
                        </div>
                        <div className="copy-wrapper">
                            <p>
                                15,988 sq ft of high quality, fully restored and
                                highly sustainable, Thameside office space in
                                London’s newest listed building.
                            </p>
                        </div>
                        <a
                            href="../dl/brochure.pdf"
                            target="_blank"
                            className="brochure"
                        >
                            <p>DOWNLOAD BROCHURE</p>
                            <img src="../images/ui/dl-arrow.svg" />
                        </a>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Nav;
