import { motion } from 'framer-motion';
import './bubble.scss';
import useIsMobile from '../../hooks/useIsMobile';

const BubbleAnimation = () => {
    const isMobile = useIsMobile();

    return (
        <div className='bubble-hero'>
            <div className="bubble-wrapper">
                {Array.from({ length: (isMobile ? 11 : 15) }).map((_, index) => (
                    <motion.div
                        initial={{ y: '-100%' }}
                        whileInView={{ y: '-50%' }}
                        transition={{ delay: 2 + Math.random() }}
                        key={index}
                        className={`bubble ${index === 7 && 'middle-one'}`}
                        viewport={{once: true}}                         
                    ></motion.div>
                ))}
            </div>
            <div className="bubble-wrapper">
            {Array.from({ length: (isMobile ? 11 : 15) }).map((_, index) => {
                    let color = Math.random() > 0.5 ? '#65724A' : 'transparent';
                    if (index === 7) {
                        color = 'transparent';
                    }

                    return (
                        <motion.div
                            initial={{ y: '-200%' }}
                            whileInView={{ y: '-50%' }}
                            transition={{ delay: 1 + Math.random() }}
                            key={index}
                            className="bubble"
                            style={{ backgroundColor: color, position: 'relative' }}
                            viewport={{once: true}}
                        ></motion.div>
                    );
                })}
            </div>
            <div className="bubble-wrapper">
            {Array.from({ length: (isMobile ? 11 : 15) }).map((_, index) => {
                    let color = Math.random() > 0.5 ? '#65724A' : 'transparent';
                    if (index === 7) {
                        color = 'transparent';
                    }
                    return (
                        <motion.div
                            initial={{ y: '-300%' }}
                            whileInView={{ y: '-50%' }}
                            transition={{ delay: Math.random() }}
                            key={index}
                            className="bubble"
                            style={{ backgroundColor: color }}
                            viewport={{once: true}}
                        ></motion.div>
                    );
                })}
            </div>
        </div>
    );
};

export default BubbleAnimation;
