const Walking = () => {

    return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <defs>
            <style>
                {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
            </style>
        </defs>
        <path
            className="cls-2"
            d="M114.84,72.41h-63.56c-10.61,0-19.22,8.6-19.22,19.22h0c0,10.61,8.6,19.22,19.22,19.22h123.51c10.61,0,19.22,8.6,19.22,19.22h0c0,10.61-8.6,19.22-19.22,19.22H24.06"
        />
        <g>
            <path
                className="cls-1"
                d="M124.84,71.67l-19.88-30.35c-6.93-10.58-5.49-24.56,3.45-33.5h0c10.42-10.42,27.32-10.42,37.75,0h0c8.94,8.94,10.38,22.92,3.45,33.5l-19.88,30.35c-1.15,1.76-3.74,1.76-4.89,0Z"
            />
            <circle className="cls-2" cx="127.29" cy="26.38" r="13.28" />
        </g>
        <circle className="cls-1" cx="24.06" cy="149.27" r="20.07" />
    </svg>)
};

export default Walking;
