import { useState } from "react";
import "./bar.scss";
import { delay, easeIn, motion } from "framer-motion";

const Bar = () => {
    const [isOpen, setIsOpen] = useState<Boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isFloorplan, setIsFloorplan] = useState<Boolean>(true);

    return (
        <motion.div initial={{y: 60}} animate={{y: 0}} transition={{delay: 7, type: 'easeIn'}} className={`bar-hero ${isOpen && "active"}`}>
            <div onClick={() => setIsOpen(!isOpen)} className="bar-bit">
                <h3>FLOORPLANS</h3>
                <img src="../images/ui/arrow.svg" />
            </div>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                    className="bar-wrapper"
                >
                    <div className="bar-content">
                        <div className="left-side">
                            <div className="table-wrapper">
                                <div className="row">
                                    <p>FLOOR</p>
                                    <p>SQ FT</p>
                                    <p>SQ M</p>
                                </div>
                                <div className="row">
                                    <div className="duplex">
                                        <div className="dup-copy">
                                            <p>DUPLEX</p>
                                        </div>
                                        <div className="dup-rows">
                                            <div
                                                className={`dup-row  ${
                                                    activeIndex === 5
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    setActiveIndex(5)
                                                }
                                            >
                                                <p>FIVE</p>
                                                <p>1,891</p>
                                                <p>175</p>
                                            </div>
                                            <div
                                                className={`dup-row  ${
                                                    activeIndex === 4
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    setActiveIndex(4)
                                                }
                                            >
                                                <p>FOUR</p>
                                                <p>2,926</p>
                                                <p>271</p>
                                            </div>
                                        </div>
                                        <div className="dup-copy-two">
                                            <p>THE CONRAN</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`row ${
                                        activeIndex === 3 ? "active" : ""
                                    }`}
                                    onClick={() => setActiveIndex(3)}
                                >
                                    <p>THREE</p>
                                    <p>2,926</p>
                                    <p>271</p>
                                </div>
                                <div
                                    className={`row ${
                                        activeIndex === 2 ? "active" : ""
                                    }`}
                                    onClick={() => setActiveIndex(2)}
                                >
                                    <p>TWO</p>
                                    <p>2,926</p>
                                    <p>271</p>
                                    <div className="dup-copy-three">
                                            <p>THE SQUIRES</p>
                                        </div>
                                </div>
                                <div
                                    className={`row ${
                                        activeIndex === 1 ? "active" : ""
                                    }`}
                                    onClick={() => setActiveIndex(1)}
                                >
                                    <p>ONE</p>
                                    <p>2,926</p>
                                    <p>271</p>
                                </div>
                                <div
                                    className={`row ${
                                        activeIndex === 0 ? "active" : ""
                                    }`}
                                    onClick={() => setActiveIndex(0)}
                                >
                                    <p>GROUND</p>
                                    <p>2,258</p>
                                    <p>209</p>
                                    <div className="dup-copy-four">
                                            <p>THE MELLOR</p>
                                        </div>
                                </div>
                                <div className="row">
                                    <p>TOTAL</p>
                                    <p>15,988</p>
                                    <p>1,485</p>
                                </div>
                            </div>
                            <div className="key">
                                <img src={`../images/floorplan/${activeIndex}.svg`} />
                                <div className="color-code">
                                    <div className="code-container">
                                        <div className="green"></div>
                                        <p>OFFICE</p>
                                    </div>
                                    <div className="code-container">
                                        <div className="green pale"></div>
                                        <p>CORE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <img
                                src={`../images/floorplan/${
                                    isFloorplan ? "floorplans" : "spaceplans"
                                }/${activeIndex}.jpg`}
                            />
                            <div className="plan-picker">
                                <p className="desktop">VIEW</p>
                                <h4
                                    onClick={() => setIsFloorplan(true)}
                                    className={`${isFloorplan && "active"}`}
                                >
                                    FLOORPLAN
                                </h4>
                                <h4
                                    onClick={() => setIsFloorplan(false)}
                                    className={`${!isFloorplan && "active"}`}
                                >
                                    SPACEPLAN
                                </h4>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </motion.div>
    );
};

export default Bar;
