import { calcLength, motion } from "framer-motion";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./intro-anim.scss";

const IntroAnimation = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {        
        const hasPlayed = Cookies.get("introAnimationPlayed");
        if (hasPlayed) {
            setIsVisible(false);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isVisible) return;

        const timer = setTimeout(() => {
            setIsVisible(false);
            Cookies.set("introAnimationPlayed", "true", { expires: 1 });
        }, 8000);

        return () => clearTimeout(timer);
    }, [isVisible]);

    const circles = [
        { cx: 137.1, cy: 137.1 },
        { cx: 411.4, cy: 137.1 },
        { cx: 685.7, cy: 137.1 },
        { cx: 960, cy: 137.1 },
        { cx: 1234.3, cy: 137.1 },
        { cx: 1508.6, cy: 137.1 },
        { cx: 1782.9, cy: 137.1 },
        { cx: 137.1, cy: 411.4 },
        { cx: 411.4, cy: 411.4 },
        { cx: 685.7, cy: 411.4 },
        { cx: 960, cy: 411.4 },
        { cx: 1234.3, cy: 411.4 },
        { cx: 1508.6, cy: 411.4 },
        { cx: 1782.9, cy: 411.4 },
        { cx: 137.1, cy: 685.7 },
        { cx: 411.4, cy: 685.7 },
        { cx: 685.7, cy: 685.7 },
        { cx: 960, cy: 685.7 },
        { cx: 1234.3, cy: 685.7 },
        { cx: 1508.6, cy: 685.7 },
        { cx: 1782.9, cy: 685.7 },
        { cx: 137.1, cy: 960 },
        { cx: 411.4, cy: 960 },
        { cx: 685.7, cy: 960 },
        { cx: 960, cy: 960 },
        { cx: 1234.3, cy: 960 },
        { cx: 1508.6, cy: 960 },
        { cx: 1782.9, cy: 960 },
    ];
    const [fadeOutStates, setFadeOutStates] = useState(
        new Array(circles.length).fill(false)
    );
    const generateRandomDelay = (maxDelay: number) => Math.random() * maxDelay;

    useEffect(() => {
        if (!isVisible) return;

        circles.forEach((_, index) => {
            const fadeOutDelay = 4 + generateRandomDelay(2);
            setTimeout(() => {
                setFadeOutStates((prevStates) => {
                    const newStates = [...prevStates];
                    newStates[index] = true;
                    return newStates;
                });
            }, fadeOutDelay * 1000);
        });
    }, [isVisible]);

    const variants = {
        animate: (custom: { delay: any }) => ({
            r: "137.1",
            opacity: 1,
            transition: { delay: custom.delay, duration: 1 },
        }),
        fadeOut: (custom: { fadeOutDelay: any }) => ({
            r: "0,0",
            transition: { delay: custom.fadeOutDelay, duration: 1 },
        }),
    };

    if (!isVisible) return null;

    return (
        <motion.div
            viewport={{ once: true }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 0, display: "none" }}
            transition={{ delay:  7 }}
            className="animations"
        >
            <motion.svg
                initial={{}}
                animate={{ display: "none" }}
                transition={{ delay: isMobile ? 6 : 7 }}
                preserveAspectRatio="xMidYMid slice"
                viewBox="0 0 1920 1097.1"
            >
                {circles.map((circle, index) => {
                    const delay = generateRandomDelay(2);
                    const fadeOutDelay = 5 + generateRandomDelay(6);

                    return (
                        <motion.circle
                            key={index}
                            custom={{ delay, fadeOutDelay }}
                            initial={{ opacity: 0 }}
                            animate={
                                fadeOutStates[index] ? "fadeOut" : "animate"
                            }
                            transition={{
                                transitionEnd: {
                                    display: "none",
                                },
                            }}
                            style={{ fill: "white" }}
                            variants={variants}
                            cx={circle.cx}
                            cy={circle.cy}
                            r={"137.1"}
                        />
                    );
                })}
            </motion.svg>
            <motion.div
                initial={{ top: isMobile ? "calc(50% - 25px)" : "calc(50% - 100px)", width: isMobile ? '200px' : "500px" }}
                animate={{
                    top: isMobile ? "20px" : "40px",
                    width: isMobile ? '106px' : "150px",
                    left: isMobile ? "20px"  : "calc(50% - 110px)",
                }}
                transition={{ delay: 6, duration: 1 }}
                className="logo"
            >
                <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 3 }}
                    src="../images/logo.svg"
                    alt="logo"
                />
            </motion.div>
        </motion.div>
    );
};

export default IntroAnimation;
