import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import SwiperGreenBar from "../../components/swipers/greenbar";
import Ventilation from "../../animations/icons/ventilation";
import Gallery from "../../components/swipers/gallery";
import BubbleAnimation from "../../animations/bubble/bubble";
import HeatPump from "../../animations/icons/heat-pump";
import Controls from "../../animations/icons/controls";
import Showers from "../../animations/icons/showers";
import Walking from "../../animations/icons/walking";
import Terrace from "../../animations/icons/terrace";
import Plants from "../../animations/icons/plants";
import Collab from "../../animations/icons/collab";
import Water from "../../animations/icons/water";
import Cycle from "../../animations/icons/cycle";
import Plug from "../../animations/icons/plug";
import EPC from "../../animations/icons/epc";
import "./design.scss";

const Design = () => {
    const [hasScrolled, setHasScrolled] = useState(false);
    const [hasFinishedScrolling, setHasFinishedScrolling] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (!hasScrolled && scrollPosition > 1) {
                setHasScrolled(true);
            }
            if (hasScrolled && scrollPosition <= 1) {
                setHasScrolled(false);
                setHasFinishedScrolling(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasScrolled]);

    const variants = {
        initial: { opacity: 1, y: 0 },
        animate: {
            y: "-100%",
            opacity: 1,
            transition: { duration: 1, ease: "easeInOut" },
        },
        reverse: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: "easeInOut" },
        },
    };

    return (
        <>
            <div className="design-one">
                <motion.div
                    initial="initial"
                    animate={hasScrolled ? "animate" : "reverse"}
                    variants={variants}
                    className="copy-container"
                >
                    <div className="copy-wrapper">
                        <h4>LONDON’S NEWEST LISTED BUILDING</h4>
                        <h2>
                            A PERFECTLY-DESIGNED HABITAT ACROSS SIX STUNNING
                            STOREYS. Home to Spacious workfloors and riverfront
                            terraces
                        </h2>
                    </div>
                </motion.div>

                <div className="image-wrapper">
                    <div className="content">
                        <div className="copy">
                            <h2>
                                15,988 sq ft high quality, fully restored and
                                highly sustainable office space in the home that
                                made British design history.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="design-two contain">
                    <div className="copy-wrapper">
                        <motion.h3
                            whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 20, opacity: 0 }}
                            transition={{ delay: 0.25, transition: 0.5 }}
                        >
                            HERITAGE WITHOUT
                            <br className="mobile" /> QUESTION IN THE CITY'S
                            <br className="mobile" /> NEWEST LISTED BUILDING
                        </motion.h3>
                        <motion.p
                            whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 20, opacity: 0 }}
                            transition={{ delay: 0.25, transition: 0.5 }}
                        >
                            Designed and constructed in 1991 as part of a
                            collaboration with renowned designer David Mellor
                            MBE, the Conran Building is a unique space where a
                            special sense of design flows through each floor.
                        </motion.p>
                        <motion.p
                            whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 20, opacity: 0 }}
                            transition={{ delay: 0.25, transition: 0.5 }}
                        >
                            Many of its non-structural features were personally
                            hand-crafted or hand-finished by Mellor, enriching
                            the meticulously designed space and underscoring the
                            nuanced interplay between form and function.
                        </motion.p>
                        <h4>View along Shad Thames</h4>
                    </div>
                    <div
                        className="image-wrapper"
                        style={{
                            backgroundImage: "url('../images/design/1.jpg')",
                        }}
                    ></div>
                </div>
            </div>
            <div className="contain-wrapper ">
                <div className="design-two-mobile contain">
                    <div className="content-wrapper">
                        <div
                            className="image-wrapper"
                            style={{
                                backgroundImage:
                                    "url('../images/design/1.jpg')",
                            }}
                        ></div>
                        <div className="copy-wrapper">
                            <p>
                                Designed and constructed in 1991 as part of a
                                unique collaboration with renowned designer
                                David Mellor MBE, the Conran Building is a
                                unique space where a unique sense of design
                                flows through each floor.
                            </p>
                            <p>
                                Many of its non-structural features were
                                personally hand-crafted or hand-finished by
                                Mellor, enriching the meticulously designed
                                space and underscoring the nuanced interplay
                                between form and function.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper ">
                <div className="design-three contain">
                    <SwiperGreenBar
                        images={[
                            "../images/design/gallery/1.jpg",
                            "../images/design/gallery/2.jpg",
                            "../images/design/gallery/3.jpg",
                            "../images/design/gallery/4.jpg",
                            "../images/design/gallery/5.jpg",
                        ]}
                        captions={[
                            "FIRST FLOOR OFFICE SPACE",
                            "FIRST FLOOR OFFICE SPACE",
                            "FIFTH FLOOR",
                            "FIFTH FLOOR",
                            "FIFTH FLOOR - LOOKING OUT ONTO THE TERRACE",
                        ]}
                    />
                    <div className="image-container">
                        <div
                            className="image-wrapper"
                            style={{
                                backgroundImage: "url(../images/design/2.jpg)",
                            }}
                        />
                        <p className="caption">FIRST FLOOR</p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="design-four contain">
                    <motion.div
                        whileInView={{ y: 0, opacity: 1 }}
                        initial={{ y: 20, opacity: 0 }}
                        transition={{ delay: 0.25, transition: 0.5 }}
                        className="title-wrapper"
                    >
                        <h4>OUTDOOR SPACE</h4>
                        <h3>
                            LIGHT-FILLED <br className="mobile" />
                            WORKFLOORS & <br className="mobile" />
                            RIVERFRONT TERRACES
                            <br className="mobile" /> LOOK OUT ONTO THE{" "}
                            <br className="mobile" />
                            UNIQUE BACKDROP OF BUTLERS WHARF
                        </h3>
                    </motion.div>
                    <Gallery
                        images={[
                            "../images/design/gallery2/3.jpg",
                            "../images/design/gallery2/2.jpg",
                            "../images/design/gallery2/1.jpg",
                            "../images/design/gallery2/5.jpg",
                            "../images/design/gallery2/4.jpg",
                        ]}
                        captions={[
                            "VIEW FROM FIFTH FLOOR ROOF TERRACE",
                            "FIFTH FLOOR ROOF TERRACE",
                            "FIFTH FLOOR ROOF TERRACE",
                            "GROUND FLOOR",
                            "FIFTH FLOOR BALCONIES WITH VIEWS OF THE SHARD",
                        ]}
                    />
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="design-five contain">
                    <motion.div
                        whileInView={{ y: 0, opacity: 1 }}
                        initial={{ y: 20, opacity: 0 }}
                        transition={{ delay: 0.25, transition: 0.5 }}
                        className="copy-wrapper"
                    >
                        <h3>
                            AN ICONIC RIVERSIDE <br /> PENTHOUSE
                        </h3>
                        <div className="p-copy">
                            <p>
                                The fourth and fifth floors were originally
                                fitted-out as an apartment that was Sir Terence
                                Conran’s home above the busy workfloors of
                                Conran & Partners.
                            </p>
                            <p>
                                Now they form a duplex space arranged around a
                                statement atrium and staircase under a glazed
                                roof that floods the floors with natural light -
                                while the roof terrace on the fifth floor
                                provides flexible outdoor space to entertain,
                                collaborate or simply relax.
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="design-six contain">
                    <div className="copy-wrapper">
                        <h4>SCHEDULE OF AREAS</h4>
                    </div>
                    <div className="copy-wrapper table">
                    
                    </div>
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="design-seven contain">
                    <div className="copy-wrapper-2">
                        <h4 className="mobile tablet">SCHEDULE OF AREAS</h4>
                        <ul>
                            <li>
                                <p>
                                    Six floors to choose from with two generous
                                    riverside terraces.
                                </p>
                            </li>
                            <li>
                                <p>
                                    All floors have river views and floor to
                                    ceiling dual aspect glazing.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Show suites, on ground, fourth and fifth
                                    floors are ready to occupy.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Floors one to three have three distinct
                                    layout options to suit your work style.
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="table-wrapper">
                        <div className="mobile row">
                            <h4>FLOOR</h4>
                            <h4>SQ FT</h4>
                            <h4>SQ M</h4>
                        </div>
                        <div className="row duplex">
                            <div className="title">
                                <p>DUPLEX</p>
                            </div>
                            <div className="content">
                                <h4>FIVE</h4>
                                <h4>1,891</h4>
                                <h4>175</h4>
                            </div>
                            <div className="content">
                                <h4>FOUR</h4>
                                <h4>2,926</h4>
                                <h4>271</h4>
                            </div>
                            <p className="squire">THE CONRAN</p>
                        </div>
                        <div className="row">
                            <h4>THREE</h4>
                            <h4>2,926</h4>
                            <h4>271</h4>
                            <p className="squire back">THE SQUIRES</p>
                        </div>
                        <div className="row">
                            <h4>TWO</h4>
                            <h4>2,926</h4>
                            <h4>271</h4>
                        </div>
                        <div className="row">
                            <h4>ONE</h4>
                            <h4>2,926</h4>
                            <h4>271</h4>
                        </div>
                        <div className="row">
                            <h4>GROUND</h4>
                            <h4>2,258</h4>
                            <h4>209</h4>
                            <p className="squire">THE MELLOR</p>
                        </div>
                        <div className="row">
                            <h4>TOTAL</h4>
                            <h4>15,988</h4>
                            <h4>1,485</h4>
                        </div>
                    </div> */}

                    <div className="building-container">
                        <div className="building-wrapper">
                            <img src="../images/design/image-drawing.svg" />
                            <div className="labels">
                                <div className="label">
                                    <div className="title">
                                        <p className="green">
                                            THE
                                            <br />
                                            CONRAN
                                        </p>
                                    </div>
                                    <div className="copy-wrapper">
                                        <div className="copy">
                                            <p>FIVE</p>
                                            <p className="green">1,891 SQ FT</p>
                                        </div>
                                        <div className="copy">
                                            <p>FOUR</p>
                                            <p className="green">2,926 SQ FT</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="label">
                                    <div className="title squire">
                                        <p className="green">
                                            THE
                                            <br />
                                            SQUIRES
                                        </p>
                                    </div>
                                    <div className="copy-wrapper">
                                        <div className="copy">
                                            <p>THREE</p>
                                            <p className="green">2,926 SQ FT</p>
                                        </div>
                                        <div className="copy">
                                            <p>TWO</p>
                                            <p className="green">2,926 SQ FT</p>
                                        </div>
                                        <div className="copy">
                                            <p>FIRST</p>
                                            <p className="green">2,926 SQ FT</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="label">
                                    <div className="title mellor">
                                        <p className="green">
                                            THE
                                            <br />
                                            MELLOR
                                        </p>
                                    </div>
                                    <div className="copy-wrapper">
                                        <div className="copy">
                                            <p>GROUND</p>
                                            <p className="green">2,258 SQ FT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="total">
                            <p>TOTAL</p>
                            <p className="green">15,988 SQ FT</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contain-wrapper design-in-detail">
                <div className="design-eight contain">
                    <h2>
                        Design <br />
                        &nbsp; &nbsp; In Every <br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Detail
                    </h2>
                </div>
            </div>

            <div className="contain-wrapper green-back">
                <div className="design-nine contain">
                    <h3 className="mobile">
                        BECOME A CUSTODIAN
                        <br /> OF QUALITY
                    </h3>
                    <div className="side-wrapper">
                        <div className="icons-wrapper">
                            <div className="icon-container">
                                <Plug />
                                <p className="copy">
                                    100% <br /> ELECTRIC
                                </p>
                            </div>
                            <div className="icon-container">
                                <HeatPump />
                                <p className="copy">
                                    AIR SOURCE <br /> HEAT PUMP
                                </p>
                            </div>
                            <div className="icon-container">
                                <Controls />
                                <p className="copy">
                                    INTELLIGENT <br /> CONTROLS
                                </p>
                            </div>
                            <div className="icon-container">
                                <EPC />
                                <p className="copy">EPC RATING B</p>
                            </div>
                            <div className="icon-container">
                                <Ventilation />
                                <p className="copy">
                                    NATURAL <br /> VENTILATION
                                </p>
                            </div>
                            <div className="icon-container">
                                <Water />
                                <p className="copy">
                                    WATER SAVING <br /> MEASURES
                                </p>
                            </div>
                            <div className="icon-container">
                                <Cycle />
                                <p className="copy">
                                    EMPLOYEE CYCLE <br /> STORAGE
                                </p>
                            </div>
                            <div className="icon-container">
                                <Showers />
                                <p className="copy">
                                    SHOWERS, LOCKERS & <br /> CHANGING
                                    FACILITIES
                                </p>
                            </div>
                            <div className="icon-container">
                                <Walking />
                                <p className="copy">
                                    WALKING DISTANCE <br /> TO LONDON BRIDGE
                                </p>
                            </div>
                            <div className="icon-container">
                                <Terrace />
                                <p className="copy">
                                    EXTERNAL TERRACES <br /> & BALCONIES
                                </p>
                            </div>
                            <div className="icon-container">
                                <Plants />
                                <p className="copy">
                                    INDOOR & OUTDOOR <br /> PLANTING
                                </p>
                            </div>
                            <div className="icon-container">
                                <Collab />
                                <p className="copy">
                                    COLLABORATION &<br /> BREAKOUT SPACE
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="copy-side">
                        <h3 className="desktop">
                            BECOME A CUSTODIAN OF QUALITY
                        </h3>
                        <div className="spec-wrapper">
                            <div className="title-wrapper">
                                <h4>SUMMARY SPEC</h4>
                                <h4>SUSTAINABILITY</h4>
                            </div>

                            <div className="content-wrapper">
                                <div className="content">
                                    <ul>
                                        <li>
                                            <p>Fully refurbished</p>
                                        </li>
                                        <li>
                                            <p>
                                                Full air conditioning (Daikin)
                                                and mechanical ventilation
                                            </p>
                                        </li>
                                        <li>
                                            <p>1 passenger lift</p>
                                        </li>
                                        <li>
                                            <p>Fully fitted space</p>
                                        </li>
                                        <li>
                                            <p>Fibre enabled</p>
                                        </li>
                                        <li>
                                            <p>Concierge reception</p>
                                        </li>
                                        <li>
                                            <p>
                                                Seamless end of journey
                                                facilities — external ramp to
                                                basement cycle storage and
                                                shower rooms, straight into the
                                                lift
                                            </p>
                                        </li>
                                        <li>
                                            <p>5 showers (1 accessible)</p>
                                        </li>
                                        <li>
                                            <p>New WCs on every floor</p>
                                        </li>
                                        <li>
                                            <p>24 lockers</p>
                                        </li>
                                        <li>
                                            <p>20 cycle storage spaces</p>
                                        </li>
                                        <li>
                                            <p>4 E-bike charging points</p>
                                        </li>
                                        <li>
                                            <p>Complimentary towel service</p>
                                        </li>
                                        <li>
                                            <p>
                                                Floor-to-ceiling sliding
                                                windows, with Juliet balconies
                                                on the fourth floor
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <p>EPC Rating “B”</p>
                                        </li>
                                        <li>
                                            <p>
                                                Natural ventilation optionality,
                                                all floors have full height
                                                opening windows and
                                                terraces/balconies at Ground,
                                                4th, 5th floors
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Re-use of key architectural
                                                features including light
                                                fittings repurposed with LEDs
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Fully electric heating and
                                                cooling system
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                High efficiency LED lighting
                                                throughout
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Cycle and public transport led
                                                scheme
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Indoor planting throughout and
                                                on external terraces
                                            </p>
                                        </li>
                                        <li>
                                            <p>Thameside terrace</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="design-ten">
                <BubbleAnimation />
            </div>
        </>
    );
};

export default Design;
