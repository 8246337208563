import React from "react";
import "../../scss/global.scss";
import { motion } from "framer-motion";
interface SpecBubbleProps {
    text: string;
    radius?: number;
    fontSize?: number;
    textColor?: string;
    circleColor?: string;
    number?: string | null;
}

const SpecBubble: React.FC<SpecBubbleProps> = ({
    text,
    radius = 75,
    fontSize = 14,
    textColor = "black",
    circleColor = "black",
    number = null,
}) => {
    const pathDefinition = `
        M 100, 100
        m -${radius + 8}, -2
        a ${radius + 4},${radius + 4} 100 1,1 ${radius * 2.2},0
        a ${radius + 4},${radius + 8} 0 1,1 -${radius * 2.2},0
    `;

    return (
        <svg
            width="200"
            height="200"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
        >
            <motion.circle
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                cx="100"
                cy="100"
                r={radius}
                transition={{ delay: 1 }}
                viewport={{ once: true, amount: 1 }}
                fill={circleColor}
                fontFamily="Ping Pong"
            />
            <defs>
                <path id="circlePath" d={pathDefinition} />
            </defs>
            <motion.text fill={textColor} fontSize={fontSize}>
                <motion.textPath
                    viewport={{ once: true }}
                    initial={{ opacity: 0, startOffset: "-25%" }}
                    whileInView={{ opacity: 1, startOffset: "25%" }}
                    transition={{ delay: 1.25 }}
                    href="#circlePath"
                >
                    {text}
                </motion.textPath>
            </motion.text>
            {number !== null && (
                <motion.text
                    viewport={{ once: true }}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 1.25 }}
                    x="100"
                    y="105"
                    fontSize={65}
                    fontWeight="bold"
                    textAnchor="middle"
                    fill={"white"}
                    dominantBaseline="middle"
                >
                    {number}
                </motion.text>
            )}
        </svg>
    );
};

export default SpecBubble;
