import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./swipers.scss";
import { useState } from "react";

interface GalleryPropsMobile {
    images: string[];
    captions: string[];
}

const GalleryIconMobile: React.FC<GalleryPropsMobile> = ({
    images,
    captions,
}) => {
    const [realIndex, setRealIndex] = useState(0);

    return (
        <div className="gallery-wrapper-mobile">
            <SwiperComponent
                id="icon-gallery-mobile"
                modules={[Navigation]}
                spaceBetween={5}
                slidesPerView={1.25}    
                centeredSlides={true}
                onSwiper={(swiper: any) => setRealIndex(swiper.realIndex)}
                onSlideChange={(swiper: any) => setRealIndex(swiper.realIndex)}
                navigation={{
                    nextEl: ".next-arrow-gallery-mobile",
                    prevEl: ".prev-arrow-gallery-mobile",
                }}
                loop={true}
                breakpoints={{
                    768: {
                        spaceBetween: 20,
                    },
                    1024: {
                        spaceBetween: 20,
                    },
                }}
            >
                {images.map((image: string, index: number) => {
                    return (
                        <SwiperSlide key={index}>
                            <div
                                className="swiper-image"
                                style={{ backgroundImage: `URL(${image})` }}
                            />
                        </SwiperSlide>
                    );
                })}
            </SwiperComponent>
            <div className="nav-bar">
                <div className="prev-arrow-gallery-mobile">
                    <img src="../images/ui/arrow.svg" />
                </div>
                <h4>{captions[realIndex]}</h4>
                <div className="next-arrow-gallery-mobile">
                    <img src="../images/ui/arrow.svg" />
                </div>
            </div>
        </div>
    );
};

export default GalleryIconMobile;
