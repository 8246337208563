const HeatPump = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
        >
            <defs>
                <style>
                    {`
            .cls-a {
              fill: #f5f5f0;
            }
      
            .cls-b {
              fill: #66734a;
            }
      
            .cls-c {
              fill: none;
              stroke: #f5f5f0;
              stroke-miterlimit: 10;
              stroke-width: 3.36px;
            }
              `}
                </style>
            </defs>
            <g>
                <path
                    className="cls-b"
                    d="M82.11,44.5c30.66-.09,55.58,24.69,55.67,55.35.09,30.66-24.69,55.58-55.35,55.67-30.66.09-55.58-24.69-55.67-55.35-.18-30.57,24.46-55.49,55.03-55.67.11,0,.21,0,.32,0Z"
                />
                <path
                    className="cls-c"
                    d="M19.41,30.16h161.19c4.48,0,8.12,3.64,8.12,8.12v131.55H11.28V38.29c0-4.48,3.64-8.12,8.12-8.12Z"
                />
                <line
                    className="cls-c"
                    x1="161.9"
                    y1="52.21"
                    x2="161.9"
                    y2="145.52"
                />
                <g>
                    <path
                        className="cls-c"
                        d="M82.27,100.01c11.52,0,20.86,9.34,20.86,20.86s-9.34,20.86-20.86,20.86v-41.73Z"
                    />
                    <path
                        className="cls-c"
                        d="M82.27,100.01c-11.52,0-20.86-9.34-20.86-20.86s9.34-20.86,20.86-20.86v41.73Z"
                    />
                    <path
                        className="cls-c"
                        d="M82.27,100.01c0-11.52,9.34-20.86,20.86-20.86,11.52,0,20.86,9.34,20.86,20.86h-41.73Z"
                    />
                    <path
                        className="cls-c"
                        d="M82.27,100.01c0,11.52-9.34,20.86-20.86,20.86s-20.86-9.34-20.86-20.86h41.73Z"
                    />
                </g>
            </g>
            <circle className="cls-a" cx="82.27" cy="100.01" r="6.25" />
            <circle className="cls-b" cx="161.9" cy="70.61" r="6.72" />
        </svg>
    );
};

export default HeatPump;
