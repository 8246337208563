const Cycle = ({ ...props }) => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
        >
            <defs>
                <style>
                    {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
                </style>
            </defs>
            <ellipse
                className="cls-1"
                cx="161.67"
                cy="126.57"
                rx="38.27"
                ry="37.79"
            />
            <ellipse
                className="cls-1"
                cx="38.33"
                cy="126.57"
                rx="38.27"
                ry="37.79"
            />
            <path
                className="cls-2"
                d="M42.85,42.54h31.27M55.32,42.54l41.91,84.76M38.33,126.57l27.09-62.38h73.88l.07.14-42.14,62.97-58.9-.73ZM161.67,127.3l-31.49-91.65h-24.51"
            />
        </svg>
    );
};

export default Cycle;
