const Plug = ({ ...props }) => {

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"           
        >
            <defs>
                <style>
                    {`
              .cls-1 {
                fill: #66734a;
              }
        
              .cls-2 {
                fill: none;
                stroke: #f5f5f0;
                stroke-miterlimit: 10;
                stroke-width: 3.36px;
              }
                `}
                </style>
            </defs>
            <path
                className="cls-1"
                d="M112.22,77h25.17c.39,0,.68.35.61.73l-7.26,39.56c-.13.68.79,1.02,1.14.42l31.47-54.09c.24-.41-.06-.93-.53-.93h-25.17c-.39,0-.68-.35-.61-.73l7.26-39.56c.13-.68-.79-1.02-1.14-.42l-31.47,54.09c-.24.41.06.93.53.93Z"
            />
            <line
                className="cls-2"
                x1="37.52"
                y1="53.96"
                x2="37.52"
                y2="24.38"                
            />
            <line
                className="cls-2"
                x1="77.99"
                y1="53.96"
                x2="77.99"
                y2="24.38"                
            />
            <path
                className="cls-2"
                d="M57.76,71.58v45.49c0,24.31,19.7,44.01,44.01,44.01h0c24.31,0,44.01-19.7,44.01-44.01"                
            />
            <path
                className="cls-1"
                d="M87.4,55.6c0,16.37-13.27,29.64-29.64,29.64s-29.64-13.27-29.64-29.64h59.29Z"             
            />
            <rect
                className="cls-1"
                x="28.11"
                y="45.73"
                width="59.29"
                height="9.87"                
            />
        </svg>
    );
};

export default Plug;
