const Controls = () => {
    return (
        <>
            <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"             
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.28 31.6599C53.4854 31.6599 51.22 33.9254 51.22 36.7199V163.27C51.22 166.064 53.4854 168.33 56.28 168.33H143.72C146.515 168.33 148.78 166.064 148.78 163.27V36.7199C148.78 33.9254 146.515 31.6599 143.72 31.6599H56.28ZM48.22 36.7199C48.22 32.2685 51.8286 28.6599 56.28 28.6599H143.72C148.171 28.6599 151.78 32.2685 151.78 36.7199V163.27C151.78 167.721 148.171 171.33 143.72 171.33H56.28C51.8286 171.33 48.22 167.721 48.22 163.27V36.7199Z"
                    fill="#F6F5F1"                    
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M62.65 63.0901H82.39V82.8301H62.65V63.0901ZM65.65 66.0901V79.8301H79.39V66.0901H65.65Z"
                    fill="#F6F5F1"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M90.13 63.0901H109.87V82.8301H90.13V63.0901ZM93.13 66.0901V79.8301H106.87V66.0901H93.13Z"
                    fill="#F6F5F1"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M117.61 63.0901H137.35V82.8301H117.61V63.0901ZM120.61 66.0901V79.8301H134.35V66.0901H120.61Z"
                    fill="#F6F5F1"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M62.65 45.1699H101.5V48.1699H62.65V45.1699Z"
                    fill="#F6F5F1"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M62.65 99.2302H137.35V102.23H62.65V99.2302Z"
                    fill="#F6F5F1"                 
                />
                <path
                    d="M127.48 100.73C127.48 96.1075 123.733 92.3601 119.11 92.3601C114.487 92.3601 110.74 96.1075 110.74 100.73C110.74 105.353 114.487 109.1 119.11 109.1C123.733 109.1 127.48 105.353 127.48 100.73Z"
                    fill="#66734B"                 
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M119.11 93.8601C115.316 93.8601 112.24 96.9359 112.24 100.73C112.24 104.524 115.316 107.6 119.11 107.6C122.904 107.6 125.98 104.524 125.98 100.73C125.98 96.9359 122.904 93.8601 119.11 93.8601ZM109.24 100.73C109.24 95.2791 113.659 90.8601 119.11 90.8601C124.561 90.8601 128.98 95.2791 128.98 100.73C128.98 106.181 124.561 110.6 119.11 110.6C113.659 110.6 109.24 106.181 109.24 100.73Z"
                    fill="#66734B"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M62.65 141.79H137.35V144.79H62.65V141.79Z"
                    fill="#F6F5F1"
                />
                <path
                    d="M127.48 143.29C127.48 138.667 123.733 134.92 119.11 134.92C114.487 134.92 110.74 138.667 110.74 143.29C110.74 147.913 114.487 151.66 119.11 151.66C123.733 151.66 127.48 147.913 127.48 143.29Z"
                    fill="#66734B"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M119.11 136.42C115.316 136.42 112.24 139.496 112.24 143.29C112.24 147.084 115.316 150.16 119.11 150.16C122.904 150.16 125.98 147.084 125.98 143.29C125.98 139.496 122.904 136.42 119.11 136.42ZM109.24 143.29C109.24 137.839 113.659 133.42 119.11 133.42C124.561 133.42 128.98 137.839 128.98 143.29C128.98 148.741 124.561 153.16 119.11 153.16C113.659 153.16 109.24 148.741 109.24 143.29Z"
                    fill="#66734B"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M62.65 120.51H137.35V123.51H62.65V120.51Z"
                    fill="#F6F5F1"
                />
                <path
                    d="M72.52 122.01C72.52 126.633 76.2674 130.38 80.89 130.38C85.5126 130.38 89.26 126.633 89.26 122.01C89.26 117.387 85.5126 113.64 80.89 113.64C76.2674 113.64 72.52 117.387 72.52 122.01Z"
                    fill="#66734B"                 

                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M80.89 128.88C84.6842 128.88 87.76 125.804 87.76 122.01C87.76 118.216 84.6842 115.14 80.89 115.14C77.0958 115.14 74.02 118.216 74.02 122.01C74.02 125.804 77.0958 128.88 80.89 128.88ZM90.76 122.01C90.76 127.461 86.3411 131.88 80.89 131.88C75.439 131.88 71.02 127.461 71.02 122.01C71.02 116.559 75.439 112.14 80.89 112.14C86.3411 112.14 90.76 116.559 90.76 122.01Z"
                    fill="#66734B"                 
                />
            </svg>
        </>
    );
};

export default Controls;
