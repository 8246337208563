import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import "swiper/swiper-bundle.css";
import "./swipers.scss";
import { useState } from "react";

interface SwiperGreenBarProps {
    images: string[];
    captions: string[];
}

const SwiperGreenBar: React.FC<SwiperGreenBarProps> = ({
    images,
    captions,
}) => {
    const [realIndex, setRealIndex] = useState(0);

    return (
        <SwiperComponent
            id="greenbar"
            modules={[Navigation]}
            onSwiper={(swiper: any) => setRealIndex(swiper.realIndex)}
            onSlideChange={(swiper: any) => setRealIndex(swiper.realIndex)}
            navigation={{
                nextEl: ".next-arrow",
                prevEl: ".prev-arrow",
            }}
            loop={true}
        >
            {images.map((image: string, index: number) => {
                return (
                    <SwiperSlide key={index}>
                        <div
                            className="swiper-image"
                            style={{ backgroundImage: `URL(${image})` }}
                        />
                    </SwiperSlide>
                );
            })}
            <div className="nav-bar">
                <div className="content-wrapper">
                    <div className="prev-arrow"><img src="../images/ui/arrow.svg" /></div>
                    <h4>
                        0{realIndex + 1} / 0{images.length}
                    </h4>
                    <h4>{captions[realIndex]}</h4>
                </div>
                <div className="next-arrow"><img src="../images/ui/arrow.svg"/></div>
            </div>
        </SwiperComponent>
    );
};

export default SwiperGreenBar;
