const Water = () => {

    return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <defs>
            <style>
                {`
      .cls-1 {
        fill: #66734a;
      }

      .cls-2 {
        fill: none;
        stroke: #f5f5f0;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
        `}
            </style>
        </defs>
        <circle className="cls-2" cx="50.27" cy="39.84" r="9.73" />
        <circle className="cls-2" cx="159.86" cy="59.21" r="18.9" />
        <g>
            <path
                className="cls-1"
                d="M98.01,39.84c17.88,30.89,47.05,54.78,47.05,83.01,0,25.88-21.17,47.05-47.05,47.05s-47.05-21.17-47.05-47.05c0-28.23,29.17-52.12,47.05-83.01Z"
            />
            <path
                className="cls-2"
                d="M64.07,122.85c0,18.74,15.19,33.93,33.93,33.93"
            />
        </g>
    </svg>)
};

export default Water;
