import { motion } from "framer-motion";
import { useState } from "react";

const BiosContent = [
    {
        icon: "SIR MICHAEL HOPKINS",
        content: [
            {
                text: "In 1976, The RIBA Royal Gold Medal-winning architect founded Hopkins Architects with his wife Patty, and was part of a select group of leading British architects regarded as the founders of the ‘High-Tech’ architectural movement — along with Norman Foster, Richard Rogers and Nick Grimshaw.",
            },
            {
                text: "",
            },
        ],
    },
    {
        icon: "DAVID MELLOR MBE",
        content: [
            {
                text: "A designer renowned for his contributions to modern design and craftsmanship, he gained acclaim in the 1960s with his iconic ‘Pride’ cutlery and later became known for his work in urban design, including redesigning traffic lights across the UK. ",
            },
            {
                text: "Mellor’s commitment to quality craftsmanship and design left a lasting legacy, inspiring appreciation for beauty and utility in everyday objects.",
            },
        ],
    },
    {
        icon: "SIR TERENCE CONRAN",
        content: [
            {
                text: "A pioneering British designer, entrepreneur, and restaurateur renowned for shaping modern design and lifestyle, he founded Habitat in 1964, revolutionizing home furnishings. Emphasizing functionality and elegance at affordable prices. Conran’s minimalist aesthetic and innovative approach extended beyond design, into hospitality and urban regeneration — most notably the regeneration of Shad Thames with the establishment of the Design Museum.",
            },
            {
                text: "Knighted in 1983, his legacy of accessible design and entrepreneurial spirit continues to inspire across industries worldwide.",
            },
        ],
    },
    {
        icon: "MICHAEL SQUIRE",
        content: [
            {
                text: "Born in 1946, Michael James Squire studied architecture at St John’s College, Cambridge, and established Michael Squire Associates with Paul Harrison in 1976. The business evolved into Squire & Partners and – under his direction – became one of the UK’s most respected architecture practices.",
            },
            {
                text: "Now led by his son Henry, and fellow partners Tim Gledstone and Murray Levinson, Squire & Partners has a reputation for beautiful, crafted buildings in sensitive urban locations, winning it a significant number of design awards.",
            },
        ],
    },
];

const Bios = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div>
            <div className="contain-wrapper" id="icons">
                <div className="icon-two contain">
                    <div className="content-wrapper">
                        <div
                            className="image-wrapper"
                            style={{
                                backgroundImage: `url(../images/icon/portraits/${activeIndex}.jpg`,
                            }}
                        ></div>
                        <motion.div
                            whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 20, opacity: 0 }}
                            transition={{ delay: 1, transition: 0.5 }}
                            className="copy-wrapper"
                        >
                            <h2>{BiosContent[activeIndex].icon}</h2>
                            <p>{BiosContent[activeIndex].content[0].text}</p>
                            <p>{BiosContent[activeIndex].content[1].text}</p>
                            <div className="cap">
                                <h4>Above: {BiosContent[activeIndex].icon}</h4>
                            </div>
                        </motion.div>
                    </div>
                    <div className="name-wrapper desktop">
                        <h1
                            className={`${activeIndex == 0 && "active"}`}
                            onClick={() => setActiveIndex(0)}
                        >
                            HOPKINS.
                        </h1>
                        <h1
                            className={`${activeIndex == 1 && "active"}`}
                            onClick={() => setActiveIndex(1)}
                        >
                            MELLOR.
                        </h1>
                        <h1
                            className={`${activeIndex == 2 && "active"}`}
                            onClick={() => setActiveIndex(2)}
                        >
                            CONRAN.
                        </h1>
                        <h1
                            className={`${activeIndex == 3 && "active"}`}
                            onClick={() => setActiveIndex(3)}
                        >
                            SQIURE.
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bios;
